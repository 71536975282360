import images from './images/logsibalec2.png'
import React, { useState, useEffect } from 'react';

const NavBar = () => {

    const [navbar,setNavBar]= useState(false);

    const changeBackground=() =>{
        if(window.scrollY > 5)
        {
            document.getElementById("App-header").className="background-header";
        }
        else
        {
            document.getElementById("App-header").className="App-header";
        }
        
        

    }

    window.addEventListener('scroll',changeBackground);


    return (
        <header className="App-header" id="App-header">
            <nav class="navbar navbar-expand-lg">
                <div class="container">
                    <img src={images} width="30%" height="10%" />
                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarResponsive" >
                        <ul class="navbar-nav ml-auto">
                            <li class="nav-item active">
                                <a class="nav-link" href="/">Beranda
                                </a>
                            </li>
                            <li class="nav-item ">
                                <a class="nav-link" href="#contact">Hubungi Kami</a>
                            </li>
                            <li class="nav-item ">
                                <a class="nav-link" href="#about">Tentang Kami</a>
                            </li>
                            <li class="nav-item ">
                                <a class="nav-link" href="/pricelist">Price list</a>
                            </li>
                            <li class="nav-item ">
                                <a class="nav-link" href="https://www.ptsibalec.com/sibalec%20web/login.php">Order now</a>
                            </li>
                            <li class="nav-item ">
                                <a class="nav-link" href="#products">Produk</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    );
}

export default NavBar;