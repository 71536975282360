import nya from '../images/TC/tc1.png';
import nym from '../images/TC/tc2.png';
import nyaf from '../images/TC/tc3.png';
import nymhy from '../images/TC/tc4.png';
import nyyhy from '../images/TC/tc5new.png';
import tc6 from '../images/TC/tc6new.png';
import nya1 from '../images/TC/fo1.png';
import nym1 from '../images/TC/fo2.png';
import nyaf1 from '../images/TC/fo3.png';
import nymhy1 from '../images/TC/fo4.png';
import nyyhy1 from '../images/TC/fo5.png';
import { Container, Button, Alert } from 'react-bootstrap';
import { CSSTransition } from 'react-transition-group';
import './product.css';

import React, { useState } from 'react';

const Tc = () => {


    const [showButton, setShowButton] = useState(true);
    const [showMessage, setShowMessage] = useState(false);

    return (
        <body>

            <Container style={{ paddingTop: '8rem' }}>
                {showButton && (
                    <Button
                        onClick={() => setShowMessage(true)}
                        size="lg"
                    >
                        Click me
                    </Button>
                )}
                <CSSTransition
                    in={showMessage}
                    timeout={300}
                    classNames="alert"
                    unmountOnExit
                    onEnter={() => setShowButton(false)}
                    onExited={() => setShowButton(true)}
                >
                    <Alert
                        variant="primary"
                        onClose={() => setShowMessage(false)}
                    >
                        <Button onClick={() => setShowMessage(false)}>
                            Close
                        </Button>
                    </Alert>
                </CSSTransition>
            </Container>


            <Container>
                <div className="row">
                    <div className="col-sm" >
                        {showButton && (
                            <Button
                                onClick={() => setShowMessage(true)}
                            >
                                <img src={nya} />
                            </Button>
                        )}
                        <CSSTransition
                            in={showMessage}
                            timeout={300}
                            classNames="alert"
                            unmountOnExit
                            onEnter={() => setShowButton(false)}
                            onExited={() => setShowButton(true)}
                        >
                            <div
                                onClose={() => setShowMessage(false)}
                            >
                                <img src={nya1} />
                            </div>
                        </CSSTransition>
                    </div>
                    <div className="col-sm" >
                        {showButton && (
                            <Button
                                onClick={() => setShowMessage(true)}
                            >
                                <img src={nym} />
                            </Button>
                        )}
                        <CSSTransition
                            in={showMessage}
                            timeout={300}
                            classNames="alert"
                            unmountOnExit
                            onEnter={() => setShowButton(false)}
                            onExited={() => setShowButton(true)}
                        >
                            <div
                                onClose={() => setShowMessage(false)}
                            >
                                <img src={nym1} />
                            </div>
                        </CSSTransition>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm" >
                        {showButton && (
                            <Button
                                onClick={() => setShowMessage(true)}
                            >
                                <img src={nyaf} />
                            </Button>
                        )}
                        <CSSTransition
                            in={showMessage}
                            timeout={300}
                            classNames="alert"
                            unmountOnExit
                            onEnter={() => setShowButton(false)}
                            onExited={() => setShowButton(true)}
                        >
                            <div
                                onClose={() => setShowMessage(false)}
                            >
                                <img src={nyaf1} />
                            </div>
                        </CSSTransition>
                    </div>
                    <div className="col-sm" >
                        {showButton && (
                            <Button
                                onClick={() => setShowMessage(true)}
                            >
                                <img src={nymhy} />
                            </Button>
                        )}
                        <CSSTransition
                            in={showMessage}
                            timeout={300}
                            classNames="alert"
                            unmountOnExit
                            onEnter={() => setShowButton(false)}
                            onExited={() => setShowButton(true)}
                        >
                            <div
                                onClose={() => setShowMessage(false)}
                            >
                                <img src={nymhy1} />
                            </div>
                        </CSSTransition>
                    </div>
                </div>


                <div className="row">
                    <div className="col-sm" >
                        {showButton && (
                            <Button
                                onClick={() => setShowMessage(true)}
                            >
                                <img src={nyyhy} />
                            </Button>
                        )}
                        <CSSTransition
                            in={showMessage}
                            timeout={300}
                            classNames="alert"
                            unmountOnExit
                            onEnter={() => setShowButton(false)}
                            onExited={() => setShowButton(true)}
                        >
                            <div
                                onClose={() => setShowMessage(false)}
                            >
                                <img src={tc6} />
                            </div>
                        </CSSTransition>
                    </div>

                    <div className="col-sm" >
                        {showButton && (
                            <Button
                                onClick={() => setShowMessage(true)}
                            >
                                <img src={nyyhy} />
                            </Button>
                        )}
                        <CSSTransition
                            in={showMessage}
                            timeout={300}
                            classNames="alert"
                            unmountOnExit
                            onEnter={() => setShowButton(false)}
                            onExited={() => setShowButton(true)}
                        >
                            <div
                                onClose={() => setShowMessage(false)}
                            >
                                <img src={nyyhy1} />
                            </div>
                        </CSSTransition>
                    </div>
                </div>



            </Container>


        </body >
    );
}

export default Tc;