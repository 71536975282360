import './App.css';
import Navbar from './NavBar';
import Home from './Home';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Footer from './footer';
import 'bootstrap/dist/css/bootstrap.min.css';

import Bw from './product/bw';
import Tc from './product/tc';
import Enamel from './product/enamel';
import PriceList from './pricelist';


function App() {

  document.title="PT.SIBALEC";
  return (
    <Router>
      <div className="App">
        <Navbar />

        <div className="body">
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/pricelist">
              <PriceList />
            </Route>


            <Route exact path="/bw">
              <Bw />
            </Route>
            <Route exact path="/fx">
              <Bw />
            </Route>
            <Route exact path="/lv">
              <Home />
            </Route>
            
            <Route exact path="/tc">
              <Tc />
            </Route>
            <Route exact path="/mv">
              <Home />
            </Route>
            <Route exact path="/enamel">
              <Enamel />
            </Route>
          </Switch>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
