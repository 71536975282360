import Typing from 'react-typing-animation';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import banner1 from './images/banner1.jpg';
import Krysibalec from './images/KRYSIBALEC.png';
import Gedung1 from './images/gedung1.png';
import Depoygy from './images/DEPOYGY.png';
import Aos from 'aos';
import 'aos/dist/aos.css';
import React,{ useEffect } from 'react';
import Bwsp from './images/BWSP.png';
import FlexiSP from './images/FlexiSP.png';
import LVSP from './images/LVSP.png';
import TCSP from './images/TCSP.png';
import MVSP from './images/MVSP.png';
import Enamelsp from './images/ENAMELSP.png';
import Alamat from './images/alamat.png';
import Phone from './images/phone.png';
import Wha from './images/WA.png';
import Fax from './images/fax.png';
import Mail from './images/mail.png';


 
const Home = () => {

    useEffect(()=>{
        Aos.init({duration:2000});
    },[]);

    return (
        <body>

            <section id="caraousell">
                <Carousel
                    autoPlay={true}
                    infiniteLoop={true}
                    swipeable={true}
                    showThumbs={false}
                >
                    <div  className="item">
                        <div  className="img-fill">
                            <img src={banner1} />
                        </div>
                        <p  classNameName="legend">Menjual Produk Supreme Kabel</p>
                    </div >
                    <div  className="item">
                        <div  className="img-fill">
                            <img src={Gedung1} />
                        </div>
                        <p  classNameName="legend">TERPERCAYA & TERLENGKAP serta BERKUALITAS</p>
                    </div>
                    <div  className="item">
                        <div  className="img-fill">
                            <img src={Krysibalec} />
                        </div>
                        <p  classNameName="legend">Menjual Produk Supreme Kabel</p>
                    </div>
                    <div  className="item">
                        <div  className="img-fill">
                            <img src={Depoygy} />
                        </div>
                        <p  classNameName="legend">TERPERCAYA & TERLENGKAP serta BERKUALITAS</p>
                    </div>
                </Carousel>
            </section>
            
            <section id="products">
                <div  className="services">
                    <div  className="container animation-wrapper">
                        <div  className="row">
                            <div  className="col-md-12">
                                <div  className="section-heading">
                                    <h2>Produk <em>Kami</em></h2>
                                    <span>Klik Item Yang Diinginkan</span>
                                </div>
                            </div>
                            <div  className="container ">
                                <div  className="row ">

                                    <div className="col-sm-4 center-block text-center animation animation-transition" data-Aos="flip-left" data-aos-duration="2000">
                                        <b>Building Wire:</b>
                                        <img src={Bwsp}  className="kartun" />
                                        <p><a href="/bw">Diperuntukan untuk instalasi Rumah, gedung, atau suatu bangunan.</a></p>
                                    </div>

                                    <div  className="col-sm-4 center-block text-center animation animation-transition" data-Aos="fade-down" data-aos-duration="2000">
                                        <b>Flexible Cable:</b>
                                        <img src={FlexiSP} className="kartun" />
                                        <p><a href="/fx">Diperuntukan untuk instalasi tidak tetap atau instalasi yang memerlukan kelenturan.</a></p>
                                    </div>

                                    <div className="col-sm-4 center-block text-center animation animation-transition" data-Aos="flip-right" data-aos-duration="2000">
                                        <b>Low Voltage:</b>
                                        <img src={LVSP} className="kartun" />
                                        <p><a href="/lv">Diperuntukan untuk instalasi peralatan listrik tegangan rendah (dibawah 1kv).</a></p>
                                    </div>

                                </div>
                            </div>
                            <div  className="container " styles="padding:80px">
                                <div  className="row ">

                                    <div  className="col-sm-4 center-block text-center animation animation-transition" data-Aos="fade-left" data-aos-duration="2000">
                                        <b>Telecomucation Cable:</b>
                                        <img src={TCSP}  className="kartun" />
                                        <p><a href="/tc">Diperuntukan untuk menghantarkan sinyal listrik sebagai media pengirim suara.</a></p>
                                    </div>

                                    <div  className="col-sm-4 center-block text-center animation animation-transition" data-Aos="zoom-in-up" data-aos-duration="2000">
                                        <b>Medium Voltage:</b>
                                        <img src={MVSP}  className="kartun" />
                                        <p><a href="/mv">Diperuntukan untuk instalasi peralatan listrik tegangan menengah (1 s/d 36 kV.)</a></p>
                                    </div>

                                    <div  className="col-sm-4 center-block text-center animation animation-transition" data-Aos="fade-right" data-aos-duration="2000">
                                        <b>Enamelled Wire:</b>
                                        <img src={Enamelsp}  className="kartun" />
                                        <p><a href="/enamel">Kawat tembaga Diperuntukan untuk lilitan generator, motor listrik, Transformator, dll</a></p>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <section id="about">
                <div  className="fun-facts">
                    <div  className="container">
                        <div  className="more-info-content">
                            <div  className="row">
                                <div  className="col-md-12 align-self-center">
                                    <div  className="right-content" id="typing">
                                        <Typing
                                            speed={8} 
                                        >
                                            <span>Siapa kami?</span>
                                            <h2>Ketahui tentang <em>PT SiBalec </em></h2>
                                            <p styles="text-align: justify;">Perusahaan Pemasaran Kabel Nasional yang merupakan Distributor dari salah satu produsen kabel terbesar dan terkemuka di Indonesia , yaitu PT Supreme Cable Manufacturing & Commerce Tbk ( PT Sucaco Tbk). 
                                            Memulai usaha dengan Pabrik Lampu di Sleman YOGYAKARTA, PT SiBalec didirikan oleh Bapak Erwin Suryo Raharjo dan Bapak Soepono pada tahun 1976 
                                            dengan memproduksi Lampu Pijar ,<br /> TL ( Fluorescent ) dan Lampu Dekorasi merek “ SiBalec “ dan “ dop” untuk pemasaran didalam dan luar negeri.</p>
                                            <p styles="text-align: justify;">
                                                Tahun 1990 PT SiBalec mendirikan Pabrik Gelas di Jakarta guna menunjang keperluan bahan baku dari pabrik lampu di Yogyakarta Tahun 1996 PT SiBalec melakukan Merger dengan GE Lighting , sebuah perusahaan terkemuka dari Amerika Serikat untuk mempersiapkan diri menjadi Perusahaan yang kompetitif dan siap menghadapi persaingan global di Industri Lampu .
                                                <br />
                                                Tahun 1996 melakukan kerjasama dan menjadi Mitra Bisnis LE GRAND , salah satu perusahaan terbesar di Perancis untuk produsen aksesoris alat listrik .
                                                <br />
                                                Tahun 1998 mengambil alih sebuah perusahaan kemasan untuk lampu dan makanan yaitu PT SiBalec Kemas .
                                                <br />
                                                Tahun 1999, PT SiBalec mulai berkonsentrasi penuh pada usaha Distribusi Kabel dengan ditunjuk sebagai DISTRIBUTOR untuk pemasaran kabel BUILDING WIRE merek “SUPREME” di sektor RETAIL untuk wilayah Indonesia .
                                            </p>
                                        </Typing>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="contact">
                <div  className="contact-information">
                    <div  className="container">
                        <div  className="row">
                            <div  className="col-md-4">
                                <div  className="contact-item">
                                    <i ></i>
                                    <h4>Head Office</h4>
                                    <p><img src={Alamat} alt="" height="25px" align="left" /> Jl. Bidara Raya No.1 <br /> Jakarta 14450.</p>
                                    <p><img src={Phone} alt="" height="25px" align="left" /> (021) 6600200 , 6692221 , 6631350 </p>
                                    <p><img src={Wha} alt="" height="25px" align="left" /> -</p>
                                    <p><img src={Fax} alt="" height="25px" align="left" />  (021) 6621909 </p>
                                    <p><img src={Mail} alt="" height="25px" align="left" />  admin @ptsibalec.com</p>
                                    <a href="https://goo.gl/maps/KBih3Nh7biYKXTT39" target="_Blank">Lihat di Google Maps</a>
                                </div>
                            </div>
                            <div  className="col-md-4">
                                <div  className="contact-item">
                                    <i ></i>
                                    <h4>Yogyakarta Branch</h4>
                                    <p><img src={Alamat} alt="" height="25px" align="left" />Jl. Magelang Km.9, Kloncoman No. 119 Pandowoharjo, Sleman-Yogya.</p>
                                    <p><img src={Phone} alt="" height="25px" align="left" />  (0274) 4360741, 4360745, 6631350</p>
                                    <p><img src={Wha} alt="" height="25px" align="left" /> -</p>
                                    <p><img src={Fax} alt="" height="25px" align="left" />  (0274) 4360756</p>
                                    <p> <img src={Mail} alt="" height="25px" align="left" /> sblyog @ptsibalec.com</p>
                                    <a href="https://goo.gl/maps/EqG5TzspnCXbDzBB7" target="_Blank">Lihat di Google Maps</a>
                                </div>
                            </div>
                            <div  className="col-md-4">
                                <div  className="contact-item">
                                    <i ></i>
                                    <h4>Makassar Branch</h4>
                                    <p><img src={Alamat} alt="" height="25px" align="left" />Jl.Kima 8, Kav.AA19, Rt 001/Rw 002 Bira, Tamalanrea Makassar-SulSel.</p>
                                    <p><img src={Phone} alt="" height="25px" align="left" />  (0411) 4723120, 4723122, 6631350</p>
                                    <p><img src={Wha} alt="" height="25px" align="left" /> 081536024028</p>
                                    <p><img src={Fax} alt="" height="25px" align="left" />  (0411) 4723121</p>
                                    <p> <img src={Mail} alt="" height="25px" align="left" /> sblmakassar @ptsibalec.com</p>
                                    <a href="https://goo.gl/maps/ixDYpsMBTh3JVjoDA" target="_Blank">Lihat di Google Maps</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </body>
    );
}

export default Home;