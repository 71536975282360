const Footer = () => {
    return (
        <footer>
            <div class="sub-footer">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <p>
                                Copyright © 2021 PT. Sibalec - All right reserved
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        </footer>
    );
}

export default Footer;