import nya from '../images/ENAMEL/PEW.png';
import nym from '../images/ENAMEL/PEWAI.png';
import nyaf from '../images/ENAMEL/PVF.png';
import nymhy from '../images/ENAMEL/PEWN.png';
import nyyhy from '../images/ENAMEL/UEW.png';
import eiw from '../images/ENAMEL/EIW.png';
import eiwai from '../images/ENAMEL/EIWAI.png';
import sbuew from '../images/ENAMEL/SBUEW.png';
import nya1 from '../images/ENAMEL/1.png';
import nym1 from '../images/ENAMEL/2.png';
import nyaf1 from '../images/ENAMEL/3.png';
import nymhy1 from '../images/ENAMEL/4.png';
import nyyhy1 from '../images/ENAMEL/5.png';
import eiw1 from '../images/ENAMEL/6.png';
import eiwai1 from '../images/ENAMEL/7.png';
import sbuew1 from '../images/ENAMEL/8.png';
import { Container, Button, Alert } from 'react-bootstrap';
import { CSSTransition } from 'react-transition-group';
import './product.css';

import React, { useState } from 'react';

const Enamel = () => {

    const [showButton, setShowButton] = useState(true);
    const [showMessage, setShowMessage] = useState(false);

    return (
        <body>

            <Container style={{ paddingTop: '8rem' }}>
                {showButton && (
                    <Button
                        onClick={() => setShowMessage(true)}
                        size="lg"
                    >
                        Click me
                    </Button>
                )}
                <CSSTransition
                    in={showMessage}
                    timeout={300}
                    classNames="alert"
                    unmountOnExit
                    onEnter={() => setShowButton(false)}
                    onExited={() => setShowButton(true)}
                >
                    <Alert
                        variant="primary"
                        onClose={() => setShowMessage(false)}
                    >
                        <Button onClick={() => setShowMessage(false)}>
                            Close
                        </Button>
                    </Alert>
                </CSSTransition>
            </Container>


            <Container>
                <div className="row">
                    <div className="col-sm" >
                        {showButton && (
                            <Button
                                onClick={() => setShowMessage(true)}
                            >
                                <img src={nya} />
                            </Button>
                        )}
                        <CSSTransition
                            in={showMessage}
                            timeout={300}
                            classNames="alert"
                            unmountOnExit
                            onEnter={() => setShowButton(false)}
                            onExited={() => setShowButton(true)}
                        >
                            <div
                                onClose={() => setShowMessage(false)}
                            >
                                <img src={nya1} />
                            </div>
                        </CSSTransition>
                    </div>
                    <div className="col-sm" >
                        {showButton && (
                            <Button
                                onClick={() => setShowMessage(true)}
                            >
                                <img src={nym} />
                            </Button>
                        )}
                        <CSSTransition
                            in={showMessage}
                            timeout={300}
                            classNames="alert"
                            unmountOnExit
                            onEnter={() => setShowButton(false)}
                            onExited={() => setShowButton(true)}
                        >
                            <div
                                onClose={() => setShowMessage(false)}
                            >
                                <img src={nym1} />
                            </div>
                        </CSSTransition>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm" >
                        {showButton && (
                            <Button
                                onClick={() => setShowMessage(true)}
                            >
                                <img src={nyaf} />
                            </Button>
                        )}
                        <CSSTransition
                            in={showMessage}
                            timeout={300}
                            classNames="alert"
                            unmountOnExit
                            onEnter={() => setShowButton(false)}
                            onExited={() => setShowButton(true)}
                        >
                            <div
                                onClose={() => setShowMessage(false)}
                            >
                                <img src={nyaf1} />
                            </div>
                        </CSSTransition>
                    </div>
                    <div className="col-sm" >
                        {showButton && (
                            <Button
                                onClick={() => setShowMessage(true)}
                            >
                                <img src={nymhy} />
                            </Button>
                        )}
                        <CSSTransition
                            in={showMessage}
                            timeout={300}
                            classNames="alert"
                            unmountOnExit
                            onEnter={() => setShowButton(false)}
                            onExited={() => setShowButton(true)}
                        >
                            <div
                                onClose={() => setShowMessage(false)}
                            >
                                <img src={nymhy1} />
                            </div>
                        </CSSTransition>
                    </div>
                </div>


                <div className="row">
                    <div className="col-sm" >
                        {showButton && (
                            <Button
                                onClick={() => setShowMessage(true)}
                            >
                                <img src={nyyhy} />
                            </Button>
                        )}
                        <CSSTransition
                            in={showMessage}
                            timeout={300}
                            classNames="alert"
                            unmountOnExit
                            onEnter={() => setShowButton(false)}
                            onExited={() => setShowButton(true)}
                        >
                            <div
                                onClose={() => setShowMessage(false)}
                            >
                                <img src={nyyhy1} />
                            </div>
                        </CSSTransition>
                    </div>
                    <div className="col-sm" >
                        {showButton && (
                            <Button
                                onClick={() => setShowMessage(true)}
                            >
                                <img src={eiw} />
                            </Button>
                        )}
                        <CSSTransition
                            in={showMessage}
                            timeout={300}
                            classNames="alert"
                            unmountOnExit
                            onEnter={() => setShowButton(false)}
                            onExited={() => setShowButton(true)}
                        >
                            <div
                                onClose={() => setShowMessage(false)}
                            >
                                <img src={eiw1} />
                            </div>
                        </CSSTransition>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm" >
                        {showButton && (
                            <Button
                                onClick={() => setShowMessage(true)}
                            >
                                <img src={eiwai} />
                            </Button>
                        )}
                        <CSSTransition
                            in={showMessage}
                            timeout={300}
                            classNames="alert"
                            unmountOnExit
                            onEnter={() => setShowButton(false)}
                            onExited={() => setShowButton(true)}
                        >
                            <div
                                onClose={() => setShowMessage(false)}
                            >
                                <img src={eiwai1} />
                            </div>
                        </CSSTransition>
                    </div>
                    <div className="col-sm" >
                        {showButton && (
                            <Button
                                onClick={() => setShowMessage(true)}
                            >
                                <img src={sbuew} />
                            </Button>
                        )}
                        <CSSTransition
                            in={showMessage}
                            timeout={300}
                            classNames="alert"
                            unmountOnExit
                            onEnter={() => setShowButton(false)}
                            onExited={() => setShowButton(true)}
                        >
                            <div
                                onClose={() => setShowMessage(false)}
                            >
                                <img src={sbuew1} />
                            </div>
                        </CSSTransition>
                    </div>
                </div>
            </Container>



        </body >
    );
}

export default Enamel;