import bw2021 from './images/PriceList/BW2021.png';
import FlexibleSNI2021 from './images/PriceList/FlexibleSNI2021.png';
import LV2021 from './images/PriceList/LV2021.png';
import LAN from './images/PriceList/LAN.png';
import FO from './images/PriceList/FO.png';
import TC2021 from './images/PriceList/TC2021.png';

import pdf1 from './pdf/building_wire.pdf';
import pdf2 from './pdf/KATALOG_FLEXI_CABLE.pdf';
import pdf3 from './pdf/Catalog_LAN_Cable.pdf';
import pdf4 from './pdf/Catalog_Fiber_Optic_Cable.pdf';
import pdf5 from './pdf/Catalog_Telecommunication_Cable.pdf';

const PriceList = () => {



    return (
        <body>
            <div>
                <div className="w3-container w3-content" styles="max-width:1000px;margin-top:64px">

                    <div className="w3-Third w3-center w3-margin-bottom lazy ">
                        <img className="w3-bar-item w3-round-large w3-margin-bottom " src={bw2021} styles="width:60%" />
                        <a href={pdf1} download>
                            <button className="button" onclick="document.getElementById('').style.display='inline'">Click Download Catalog Building Wire:</button>
                        </a>
                    </div>
                    <div className="w3-Third w3-center w3-margin-bottom lazy">
                        <img className="w3-round-large w3-margin-bottom" src={FlexibleSNI2021} styles="width:100px" />

                        <a href={pdf2} download>
                            <button className="button" onclick="document.getElementById('').style.display='inline'">Click Download Catalog Flexible Cable:</button>
                        </a>
                    </div>
                    <div className="w3-Third w3-center w3-margin-bottom lazy">
                        <img className="w3-round-large w3-margin-bottom" src={LV2021} styles="width:60%" />

                        <button className="button" onclick="document.getElementById('').style.display='inline'"> LOW VOLTAGE CABLE:</button>
                    </div>
                    <div className="w3-Third w3-center w3-margin-bottom lazy ">
                        <img className="w3-round-large w3-margin-bottom" src={LAN} styles="width:60%" />

                        <a href={pdf3} download>
                            <button className="button" onclick="document.getElementById('').style.display='inline'">Click Download Catalog LAN Cable:</button>
                        </a>
                    </div>
                    <div className="w3-Third w3-center w3-margin-bottom lazy">
                        <img className="w3-round-large w3-margin-bottom" src={FO} styles="width:60%" />

                        <a href={pdf4} download>
                            <button className="button" onclick="document.getElementById('').style.display='inline'">Click Download Catalog Fiber Optic Cable:</button>
                        </a>
                    </div>
                    <div className="w3-Third w3-center w3-margin-bottom lazy">
                        <img className="w3-round-large w3-margin-bottom" src={TC2021} styles="width:60%" />

                        <a href={pdf5} download>
                            <button className="button" onclick="document.getElementById('').style.display='inline'">Click Download Catalog Telecomunication Cable:</button>
                        </a>
                    </div>
                </div>
            </div>
        </body>
    );
}

export default PriceList;